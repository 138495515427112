<template>
  <Suspense v-if="isLocalEnv()">
    <div>
      <dev-panel
        style-overrides
        :module-configuration="{
          moduleConfiguration: microfrontendConfigs,
        }"
        :feature-flags="{
          updateFlag: setFeatureFlag,
          flags: featureFlags,
        }"
        :state="{
          state,
          updateState: setData,
        }"
        :overrides="{
          modules: microfrontendConfigs,
          onToggleOverride,
        }"
        log-requests
        :pkg-json="pkgJson"
      >
        <activity-feed></activity-feed>

        <div>
          <div
            v-for="[name, repo] of Object.entries(shell?.getDevInfo || {})"
            :key="name"
          >
            {{ name }} ({{ repo.instances.length }} instances)
            <dsf-toggle
              :value="repo.env === 'hot'"
              @change="(e: CustomEvent<boolean>) => onEnvChange(name as MFRepositories, e.detail)"
              dsf_label="hot"
            ></dsf-toggle>
            <dsf-button @click="onBranch(name as MFRepositories)"
              >Branch</dsf-button
            >
          </div>
        </div>
      </dev-panel>
      <div
        id="activity-feed-render-root"
        style="max-height: 100vh; height: 100%"
      ></div>
    </div>
  </Suspense>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { DevPanel } from '@sennder/shell-utilities'
import { MFRepositories } from '@sennder/microfrontend-registry'
import { isLocalEnv } from '@/common/config'
import { type SennTmsStoreData } from '@/store'
import {
  getStateData,
  getStateFeatureFlags,
  setStoreFeatureFlags,
  setStoreData,
} from '@/store'

const onEnvChange = (repo: MFRepositories, hot: boolean) => {
  const env = hot ? 'hot' : 'dev'
  console.log('Toggling', repo, 'to', env)
  shell.value?.setEnv(repo)
}

const onBranch = (repo: MFRepositories) => {
  shell.value?.setBranch(repo, 'feature-branches/feature-branch-test')
}

import router from '@/router'
import pkgJson from '../../package.json'
import { microfrontends } from '@/config/microfrontends'
import ActivityFeed from '@/components/ActivityFeed.vue'
import { shell } from '@/config/registry'

const featureFlags = computed(getStateFeatureFlags)
const state = computed(getStateData)
const microfrontendConfigs = Object.values(microfrontends)

const onToggleOverride = async (npmName: string) => {
  // If this is the module we're currently rendering, let's reload the page to mount the new version
  if (router.currentRoute.value.meta.npmName === npmName) {
    const { fullPath } = router.currentRoute.value
    await router.push('/reload')
    await router.push(fullPath)
  }
}

const setData = (state: object) => {
  setStoreData(state as SennTmsStoreData)
}

const setFeatureFlag = (key: string, value: boolean) => {
  setStoreFeatureFlags({
    ...getStateFeatureFlags(),
    [key]: value,
  })
}
</script>
