import { loggerInstance } from '@/services/logger'
import { ActivityFeed } from '@sennder/senn-node-microfrontend-interfaces'
import { microfrontends } from '@/config/microfrontends'
import { envOverrides, MicroFrontendLogger } from '@sennder/shell-utilities'
import { ENV } from '@/common/config'
import { getMfContext } from '@/router/routes'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { getStateData, getStateFeatureFlags } from '@/store'
import { watch } from 'vue'

const getFml = async () => {
  const fml = microfrontends['activity-feed']
  const isHot = envOverrides.isHot(fml.npmName)
  // Fetch the ActivityFeed module
  const { default: remoteFns } = await fml.getModule<{
    default: ActivityFeed.IActivityFeedFunctions
  }>('./remotefunctions', isHot ? 'hot' : ENV)

  return remoteFns
}

let onSharedDataChanged:
  | ((data: ActivityFeed.IActivityFeedData) => void)
  | undefined

const analytics = new AppAnalyticsProvider({
  module: 'activity-feed',
  submodule: '',
})
const logger = new MicroFrontendLogger(
  {
    codeOwners: 'FES',
    module: 'activity-feed',
  },
  () => loggerInstance
)

const getActivityFeedData = () =>
  getMfContext(microfrontends['activity-feed'].npmName, logger, analytics)

export const initActivityFeedProvider = async () => {
  watch([() => getStateData().language, getStateFeatureFlags], async () => {
    const sharedData = await getActivityFeedData()
    onSharedDataChanged?.({
      language: sharedData.data.profile.language,
      user: {
        fullNameWithoutTitle: sharedData.data.profile.name,
        uuid: sharedData.data.profile.id,
      },
      profile: sharedData.data.profile,
    })
  })

  // Function called when feed should be rendered
  const renderActivityFeed: ActivityFeed.RenderFunction = async (
    root,
    conf
  ) => {
    const fml = await getFml()

    const mfContext = await getMfContext(
      microfrontends['activity-feed'].npmName,
      logger,
      analytics
    )

    const {
      data: { profile },
    } = mfContext

    if (!profile) {
      throw new Error('state.data.profile is not initialized')
    }

    const mountFns = fml.mountActivityFeed(
      root,
      {
        ...mfContext,
        data: {
          ...mfContext.data,
          profile,
          user: {
            fullNameWithoutTitle: profile.name,
            uuid: profile.id,
          },
        },
      },
      conf
    )
    onSharedDataChanged = mountFns.onSharedDataChanged

    return mountFns
  }

  window.renderActivityFeed = renderActivityFeed
}
