import { ref } from 'vue'

import { SharedDataType } from '@sennder/senn-node-microfrontend-interfaces'
import { cloneReactiveToRaw } from '@sennder/shell-utilities'

import { logger } from '@/services/logger/loggers'
import { getStateFeatureFlags } from '@/store'

import type { AuthRemoteFunction } from '@sennder/microfrontend-registry/InternalAuthV2'
import { getFunction, MFRepositories } from '@sennder/microfrontend-registry'

export const auth = ref<AuthRemoteFunction>()

export const loadAuthRemoteFunction = async () => {
  if (!auth.value) {

    const { default: RemoteFunction } = await getFunction(
      MFRepositories.InternalAuthV2,
      'auth',
      {
        integrator: SharedDataType.SENN_TMS,
      }
    )

    auth.value = new RemoteFunction(SharedDataType.SENN_TMS, {
      logger,
      featureFlags: cloneReactiveToRaw(getStateFeatureFlags()),
    })
  }
}
