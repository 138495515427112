import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { OpsProfile } from '@sennder/senn-node-microfrontend-interfaces'

export const identifyUserInLaunchDarkly = async (profile: OpsProfile) => {
  const ldContext: LDContext = {
    kind: 'user',
    key: profile.id,
    email: profile.email,
    name: profile.name,
  }
  await identify(ldContext)
  console.info('[tms - LaunchDarkly]: User is identified in LaunchDarkly')
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info('[tms - LaunchDarkly]: User is anonymized in LaunchDarkly')
}
