import { CacheType, FederatedModule } from '@sennder/federated-module-loader'
import {
  ISennTmsSharedData,
  IWidgetSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'

import { ENV } from '@/common/config'
import { logger } from '@/services/logger/loggers'

const cacheType: CacheType = 'infinite'

/**
 * Unique micro-frontend identifier
 */
export type MicrofrontendId = keyof typeof microfrontends

/**
 * All unique micro-frontends which can be loaded in the application
 */
export const microfrontends = {
  'user-profile': new FederatedModule<ISennTmsSharedData>({
    npmName: 'user-profile-mf-component',
    devPort: 9595,
    environment: ENV,
    moduleFederationPluginName: 'userprofilemfcomponent',
    mountExposedName: './userprofilemfcomponentApp',
    logger,
    cacheType,
  }),
  auth: new FederatedModule<ISennTmsSharedData>({
    devPort: 9110,
    environment: ENV,
    moduleFederationPluginName: 'internalauthmfcomponent',
    npmName: 'internal-auth-mf-component-v2',
    mountExposedName: './internalauthmfcomponentApp',
    logger,
    cacheType,
  }),
  analysis: new FederatedModule<ISennTmsSharedData>({
    devPort: 9182,
    environment: ENV,
    moduleFederationPluginName: 'analysismfcomponent',
    npmName: 'analysis-mf-component',
    mountExposedName: './analysismfcomponentApp',
    logger,
    cacheType,
  }),
  octopus: new FederatedModule<ISennTmsSharedData>({
    devPort: 9111,
    environment: ENV,
    moduleFederationPluginName: 'octopuscoremfcomponent',
    npmName: 'octopus-core-mf-component',
    mountExposedName: './octopuscoremfcomponentApp',
    logger,
    cacheType,
  }),
  shipment: new FederatedModule<ISennTmsSharedData>({
    devPort: 9113,
    environment: ENV,
    moduleFederationPluginName: 'shipmentmfcomponent',
    npmName: 'shipment-mf-component',
    mountExposedName: './shipmentmfcomponentApp',
    logger,
    cacheType,
  }),
  shipment_modal: new FederatedModule<ISennTmsSharedData>({
    devPort: 9113,
    environment: ENV,
    moduleFederationPluginName: 'shipmentmfcomponent',
    npmName: 'shipment-mf-component',
    mountExposedName: './shipmentmfcomponentCreateShipmentModal',
    logger,
    cacheType,
  }),
  'single-view': new FederatedModule<ISennTmsSharedData>({
    devPort: 9112,
    environment: ENV,
    moduleFederationPluginName: 'singleviewmfcomponent',
    npmName: 'single-view-mf-component',
    mountExposedName: './singleviewmfcomponentApp',
    logger,
    cacheType,
  }),
  trip: new FederatedModule<ISennTmsSharedData>({
    devPort: 9199,
    environment: ENV,
    moduleFederationPluginName: 'trip_mf',
    npmName: 'trip-mf',
    mountExposedName: './tripmfApp',
    logger,
    cacheType,
  }),
  'task-allocation': new FederatedModule<ISennTmsSharedData>({
    devPort: 9185,
    environment: ENV,
    moduleFederationPluginName: 'taskallocationmf',
    npmName: 'task-allocation-mf',
    mountExposedName: './taskallocationmfApp',
    logger,
    cacheType,
  }),
  spotter: new FederatedModule<ISennTmsSharedData>({
    devPort: 9150,
    environment: ENV,
    moduleFederationPluginName: 'spotter',
    npmName: 'spotter',
    mountExposedName: './spotterApp',
    logger,
    cacheType,
  }),
  'suppliers-dashboard': new FederatedModule<ISennTmsSharedData>({
    devPort: 9900,
    environment: ENV,
    moduleFederationPluginName: 'supplier_data_micro_frontends',
    npmName: 'supplier_data_micro_frontends',
    mountExposedName: './suppliersDashboardApp',
    logger,
    cacheType,
  }),
  'vendor-profile': new FederatedModule<ISennTmsSharedData>({
    devPort: 9900,
    environment: ENV,
    moduleFederationPluginName: 'supplier_data_micro_frontends',
    npmName: 'supplier_data_micro_frontends',
    mountExposedName: './vendorProfileApp',
    logger,
    cacheType,
  }),
  'carrier-search': new FederatedModule<ISennTmsSharedData>({
    devPort: 9101,
    environment: ENV,
    moduleFederationPluginName: 'carriersearchmfcomponent',
    npmName: 'carrier-search-mf-component',
    mountExposedName: './carriersearchmfcomponentApp',
    logger,
    cacheType,
  }),
  'carrier-profile': new FederatedModule<ISennTmsSharedData>({
    devPort: 9102,
    environment: ENV,
    moduleFederationPluginName: 'carrierprofilemfcomponent',
    npmName: 'carrier-profile-mf-component',
    mountExposedName: './carrierprofilemfcomponentApp',
    logger,
    cacheType,
  }),
  'facility-management': new FederatedModule<ISennTmsSharedData>({
    devPort: 9103,
    environment: ENV,
    moduleFederationPluginName: 'facilitymanagementmfcomponent',
    npmName: 'facility-management-mf-component',
    mountExposedName: './facilitymanagementmfcomponentApp',
    logger,
    cacheType,
  }),
  'vetting-review': new FederatedModule<ISennTmsSharedData>({
    devPort: 9114,
    environment: ENV,
    moduleFederationPluginName: 'vetting_micro_frontends',
    npmName: 'vetting_micro_frontends',
    mountExposedName: './vettingReviewApp',
    logger,
    cacheType,
  }),
  'vetting-dashboard': new FederatedModule<ISennTmsSharedData>({
    devPort: 9114,
    environment: ENV,
    moduleFederationPluginName: 'vetting_micro_frontends',
    npmName: 'vetting_micro_frontends',
    mountExposedName: './vettingDashboardApp',
    logger,
    cacheType,
  }),
  blocklisting: new FederatedModule<ISennTmsSharedData>({
    devPort: 9114,
    environment: ENV,
    moduleFederationPluginName: 'vetting_micro_frontends',
    npmName: 'vetting_micro_frontends',
    mountExposedName: './blocklistingDashboardApp',
    logger,
    cacheType,
  }),
  sennteams: new FederatedModule<ISennTmsSharedData>({
    devPort: 9154,
    environment: ENV,
    moduleFederationPluginName: 'sennteamsmfcomponent',
    npmName: 'sennteams-mf-component',
    mountExposedName: './sennteamsmfcomponentApp',
    logger,
    cacheType,
  }),
  'shipper-contacts': new FederatedModule<ISennTmsSharedData>({
    devPort: 9107,
    environment: ENV,
    moduleFederationPluginName: 'shippercontactsmfcomponent',
    npmName: 'shipper-contacts-mf-component',
    mountExposedName: './shipperContactsPage',
    logger,
    cacheType,
  }),
  'customer-data': new FederatedModule<ISennTmsSharedData>({
    devPort: 9177,
    environment: ENV,
    moduleFederationPluginName: 'customerdatamfcomponents',
    npmName: 'customer-data-mf-components',
    mountExposedName: './customerDataPage',
    logger,
    cacheType,
  }),
  sennlab: new FederatedModule<ISennTmsSharedData>({
    devPort: 9106,
    environment: ENV,
    moduleFederationPluginName: 'sennlab',
    npmName: 'sennlab',
    mountExposedName: './sennlabApp',
    logger,
    cacheType,
  }),
  planner: new FederatedModule<ISennTmsSharedData>({
    devPort: 9198,
    environment: ENV,
    moduleFederationPluginName: 'plannermfcomponent',
    npmName: 'planner-mf-component',
    mountExposedName: './plannermfcomponentApp',
    logger,
    cacheType,
  }),
  contracts: new FederatedModule<ISennTmsSharedData>({
    devPort: 8084,
    environment: ENV,
    moduleFederationPluginName: 'chartering',
    npmName: 'chartering',
    mountExposedName: './charteringApp',
    logger,
    cacheType,
  }),
  marketplace: new FederatedModule<ISennTmsSharedData>({
    devPort: 9104,
    environment: ENV,
    moduleFederationPluginName: 'marketplacemfcomponent',
    npmName: 'marketplace-mf-component',
    mountExposedName: './marketplacemfcomponentApp',
    logger,
    cacheType,
  }),
  'search-bar': new FederatedModule<IWidgetSharedData>({
    devPort: 9111,
    environment: ENV,
    moduleFederationPluginName: 'searchbarmfcomponent',
    npmName: 'search-bar-mf-component',
    mountExposedName: './searchbarmfcomponentApp',
    cacheType,
  }),
  notifications: new FederatedModule<ISennTmsSharedData>({
    devPort: 9199,
    environment: ENV,
    moduleFederationPluginName: 'notificationcentermf',
    npmName: 'notification-center-mf',
    mountExposedName: './notificationcentermfApp',
    cacheType,
  }),
  'archived-order': new FederatedModule<ISennTmsSharedData>({
    devPort: 9110,
    environment: ENV,
    moduleFederationPluginName: 'archivedordermfcomponent',
    npmName: 'archived-order-mf-component',
    mountExposedName: './archivedordermfcomponentApp',
    cacheType,
  }),
  'activity-feed': new FederatedModule({
    devPort: 1111,
    environment: ENV,
    moduleFederationPluginName: 'activityfeedmfcomponent',
    npmName: 'activity-feed-mf-component',
    cacheType,
    mountExposedName: './remotefunctions',
  }),
  tendering: new FederatedModule<ISennTmsSharedData>({
    devPort: 9000,
    environment: ENV,
    moduleFederationPluginName: 'tenderingmf',
    npmName: 'tendering-mf-component',
    mountExposedName: './tenderingmfApp',
    logger,
    cacheType,
  }),
} as const satisfies {
  [TMicroFrontend: string]:
    | FederatedModule<ISennTmsSharedData>
    | FederatedModule<IWidgetSharedData>
    | FederatedModule<any>
}
