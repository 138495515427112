import { logger } from '@/services/logger/loggers'
import { auth } from '@/modules/auth'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
}

export const logout = async () => {
  const isAuthenticated = await auth.value?.isAuthenticated()

  if (isAuthenticated) {
    await auth.value?.logout()
  }

  logger.unsetUserData()
  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
  window.location.reload()
}
