import { ref, watch, WatchStopHandle } from 'vue'

import {
  CTX,
  LogLevel,
  Shell,
  TRepository,
} from '@sennder/microfrontend-registry'
import { ISennTmsSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import { MicroFrontendLogger } from '@sennder/shell-utilities'
import { deepToRaw } from '@sennder/shell-utilities/dist/functions/createMfWrapper/utils'

import { wrapAuthWithLogging } from '@/modules/utils'
import router, { INITIAL_ROUTE_PATH } from '@/router'
import { getMicrofrontendData, routeNavigationGuard } from '@/router/routes'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { loggerInstance } from '@/services/logger'
import { getStateCallbacks, getStateData, getStateProviders } from '@/store'
import { logout } from '@/store/logoutActions'
import { routes } from './routes'
import { ENV } from '@/common/config'

export const shellLogLevel = ENV === 'dev' ? LogLevel.ALL : LogLevel.OFF

export const shell = ref<Shell<ISennTmsSharedData>>()

export const getMfContext = async (
  mf: TRepository
): Promise<Readonly<CTX<ISennTmsSharedData>>> => {
  const { getAuthHeader, getAuthToken, getToken } = getStateCallbacks()

  const routeName = router.currentRoute.value.name
  const route = Object.values(routes).find((r) => r.name === routeName)

  const npmName = mf.meta.fml.npmName

  const logger: MicroFrontendLogger = new MicroFrontendLogger(
    {
      codeOwners: mf.meta.codeOwners,
      module: npmName,
    },
    () => loggerInstance
  )

  const analyticsProvider: AppAnalyticsProvider = new AppAnalyticsProvider(
    route?.context.analytics || {
      module: npmName,
      submodule: npmName,
    }
  )

  const context: CTX<ISennTmsSharedData> = {
    data: deepToRaw(
      getMicrofrontendData(npmName === 'internal-auth-mf-component-v2')
    ),
    callbacks: {
      ...getStateCallbacks(),
      getAuthHeader: wrapAuthWithLogging(getAuthHeader, logger),
      getAuthToken: wrapAuthWithLogging(getAuthToken, logger),
      getToken: wrapAuthWithLogging(getToken, logger),
      onUnauthorized: logout,
      syncParentRouter: async (route: any) => {
        const currentPath = router.currentRoute.value.fullPath
        if (
          (typeof route === 'string' && currentPath === route) ||
          (typeof route === 'object' && currentPath === route.path)
        ) {
          return
        }
        await router.push(route)
      },
    },
    providers: {
      ...getStateProviders(),
      logger,
      segment: analyticsProvider,
      analytics: analyticsProvider,
    },
    hooks: [
      (_, mfInstance) => {
        /* TODO: what's the better way to discover "pages"?
          Currently a lot of widgets still return syncChildRouter for no good reason
         */
        const isPage = !!mfInstance.syncChildRouter

        const watchHandles: WatchStopHandle[] = []

        if (isPage) {
          // sync child router with parent router
          watchHandles.push(
            watch(
              () => router.currentRoute.value,
              (to) => {
                mfInstance.syncChildRouter(to.fullPath)
              },
              {
                immediate: true,
              }
            )
          )
          // re-execute navigation guard for current route when feature flags change
          watchHandles.push(
            watch(
              () => getStateData().featureFlags,
              async () => {
                const currentRoute = router.currentRoute.value
                if (!currentRoute) {
                  return
                }
                // re-execute navigation guards for current route
                const result = await routeNavigationGuard(
                  currentRoute,
                  currentRoute
                )
                if (result !== true) {
                  if (result === false) {
                    router.push(INITIAL_ROUTE_PATH)
                  } else {
                    router.push(result)
                  }
                }
              }
            )
          )
        }

        return () => {
          if (watchHandles.length) {
            for (const unwatch of watchHandles) {
              unwatch()
            }
            watchHandles.length = 0
          }
        }
      },
    ],
  }

  return context
}
