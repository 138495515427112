import { Navigation } from '@sennder/shell-utilities'

import CreateShipment from '@/layouts/components/CreateShipment.vue'
import { AppRoute } from './routes'
import {
  canAccessCharteringPages,
  canAccessAnalysisBoard,
  canAccessShipperContactsPages,
  canAccessSupplierDataDashboardPage,
  canAccessCustomerDataPages,
  canAccessTenderingPages,
} from './user-access'

export type AppNavigation = Navigation<AppRoute>

/**
 * Main menu items: categories, leafs (links) and widgets
 */
export const navigation: AppNavigation[] = [
  {
    kind: 'widget',
    component: CreateShipment,
    icon: 'Plus',
    translationKey: '',
    dataTest: 'create-shipment',
  },
  {
    kind: 'leaf',
    route: '/single-view',
    icon: 'Eye',
    translationKey: 'single-view',
    dataTest: 'single-view',
  },
  {
    kind: 'leaf',
    route: '/analysis',
    icon: 'custom:analysis',
    translationKey: 'analysis-management',
    visibilityHandlers: [canAccessAnalysisBoard],
  },
  {
    kind: 'leaf',
    route: '/dashboard/es-execution',
    icon: 'Radar',
    translationKey: 'es-execution-board',
    dataTest: 'execution-board',
  },
  {
    kind: 'leaf',
    route: '/contract-management',
    icon: 'FileText',
    translationKey: 'contract-management',
    dataTest: 'contract-management',
  },
  {
    kind: 'leaf',
    route: '/spotter',
    icon: 'Compass',
    translationKey: 'spotter',
    dataTest: 'spotter',
  },
  {
    kind: 'category',
    icon: 'Truck',
    translationKey: 'carriers',
    dataTest: 'carriers',
    children: [
      {
        kind: 'leaf',
        route: '/carriers',
        translationKey: 'carrier-search',
        dataTest: 'carrier-search',
      },
      {
        kind: 'leaf',
        route: '/suppliers',
        translationKey: 'suppliers-dashboard',
        dataTest: 'suppliers-dashboard',
        visibilityHandlers: [canAccessSupplierDataDashboardPage],
      },
      {
        kind: 'leaf',
        route: '/vetting-dashboard',
        translationKey: 'vetting-dashboard',
        dataTest: 'vetting-dashboard',
      },
      {
        kind: 'leaf',
        route: '/blocklisting-dashboard',
        translationKey: 'blocklisting-dashboard',
        dataTest: 'blocklisting-dashboard',
      },
    ],
  },
  {
    kind: 'leaf',
    route: '/facilities',
    icon: 'Building',
    translationKey: 'facility-management',
    dataTest: 'facility-management',
  },
  {
    kind: 'leaf',
    route: '/senn-teams',
    icon: 'Users',
    translationKey: 'senn-teams',
    dataTest: 'senn-teams',
  },
  {
    kind: 'leaf',
    route: '/shipper-contacts',
    icon: 'Phone',
    translationKey: 'shipper-contacts',
    dataTest: 'shipper-contacts',
    visibilityHandlers: [canAccessShipperContactsPages],
  },
  {
    kind: 'leaf',
    route: '/customer-data',
    icon: 'Phone',
    translationKey: 'customer-data',
    dataTest: 'customer-data',
    visibilityHandlers: [canAccessCustomerDataPages],
  },
  {
    kind: 'category',
    icon: 'custom:marketplace',
    translationKey: 'tendering',
    dataTest: 'tendering',
    children: [
      {
        kind: 'leaf',
        route: '/tender-opportunities',
        translationKey: 'opportunities',
        dataTest: 'tendering-opportunities',
        visibilityHandlers: [canAccessTenderingPages],
      },
      {
        kind: 'leaf',
        route: '/tender-analytics',
        translationKey: 'analytics',
        dataTest: 'tendering-analytics',
        visibilityHandlers: [canAccessTenderingPages],
      },
    ],
  },
  {
    kind: 'category',
    icon: 'ChartGantt',
    translationKey: 'chartering',
    dataTest: 'chartering',
    children: [
      {
        kind: 'leaf',
        route: '/planner',
        icon: 'Calendar',
        translationKey: 'planner',
        dataTest: 'planner',
        visibilityHandlers: [canAccessCharteringPages],
      },
      {
        kind: 'leaf',
        route: '/contracts',
        icon: 'FileText',
        translationKey: 'contracts',
        dataTest: 'contracts',
        visibilityHandlers: [canAccessCharteringPages],
      },
      {
        kind: 'leaf',
        route: '/marketplace',
        icon: 'custom:marketplace',
        translationKey: 'marketplace',
        dataTest: 'marketplace',
        visibilityHandlers: [canAccessCharteringPages],
      },
    ],
  },
]
