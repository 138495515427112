import { USER_MANAGEMENT_API_URL } from '@/common/config'
import {
  IUser,
  IUserRole,
  OpsProfile,
} from '@sennder/senn-node-microfrontend-interfaces'
import { auth } from '@/modules/auth'

interface User {
  id: string
  auth0_id: string
  mothership_id: string
  name: string
  email: string
  picture: string
  roles: string[]
  created_at: string
  updated_at: string
  active: boolean
}

interface UserRoles {
  limit: number
  start: number
  total: number
  page_number: number
  total_pages: number
  roles: IUserRole[]
}

async function getHeaders(): Promise<Headers> {
  if (!auth.value) {
    throw new Error(
      '[tms-shell - UserManagementService - getHeaders]: Auth module is not loaded'
    )
  }
  const headers = new Headers()
  const commonHeaders = await auth.value.getCommonHeaders(
    'https://api.cloud.sennder.com'
  )
  headers.append('Content-Type', 'application/json')
  Object.entries(commonHeaders).forEach(([key, value]) => {
    headers.append(key, value)
  })
  return headers
}

function populateUserData(umsUser: User): IUser {
  const names = umsUser.name.trim().split(/\s+/)
  const lastName = names.length === 1 ? '' : names[names.length - 1]
  const firstName = umsUser.name.replace(` ${lastName}`, '')
  const user: IUser = {
    id: umsUser.mothership_id,
    uuid: umsUser.id,
    email: umsUser.email,
    username: umsUser.email,
    fullNameWithoutTitle: umsUser.name,
    firstName,
    lastName,
    language: 'en',
    uiLanguage: 'en',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    inStaffGroup: false,
    inCustomerGroup: false,
    inFinanceGroup: false,
    firstLogin: false,
    paymentTermPopUpDaysPast: 0,
    cookies: [],
    groupNames: [],
    permissions: [],
    featureFlags: [],
    positionTitle: '',
  }

  return user
}

class UserManagementService {
  public async getUserAndProfile(): Promise<{
    opsProfile: OpsProfile
    user: IUser
  }> {
    const authUser = await auth.value?.getUser()
    const url = `${USER_MANAGEMENT_API_URL}/api/users/${authUser?.uuid}`

    const response = await fetch(url, {
      method: 'GET',
      headers: await getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch user: ${response.statusText}`)
    }

    const user: User = await response.json()
    const opsProfile: OpsProfile = {
      id: user.id,
      email: user.email,
      name: user.name,
      picture: user.picture,
      language: 'en',
    }

    return { opsProfile, user: populateUserData(user) }
  }

  public async getUserRoles(userUuid: string): Promise<IUserRole[]> {
    const url = `${USER_MANAGEMENT_API_URL}/api/users/${userUuid}/roles`

    const response = await fetch(url, {
      method: 'GET',
      headers: await getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch user roles: ${response.statusText}`)
    }

    const result: IUserRole[] = await response
      .json()
      .then((data: UserRoles) => data.roles)

    return result
  }
}

export const userManagementService = new UserManagementService()
