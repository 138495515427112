import { IconName } from '@sennder/design-system-core'
import {
  FederatedModule,
  Config,
  SharedData,
} from '@sennder/federated-module-loader'
import { routes } from '@/config/routes'
import { navigation } from '@/config/navigation'
import { microfrontends } from '@/config/microfrontends'

type UrlConfig = {
  icon?: IconName
  route: string
  fml: Config
}

type RuntimeConfig<S extends SharedData> = {
  icon?: IconName
  route: string
  fml: FederatedModule<S>
}

export const getExperimentalConfig = <S extends SharedData>() => {
  const urlParams = new URLSearchParams(window.location.search)
  const rawConfig = urlParams.get('runtimeConfig')

  if (rawConfig) {
    try {
      const parsedConfig = JSON.parse(rawConfig) as UrlConfig
      const config: RuntimeConfig<S> = {
        icon: parsedConfig.icon,
        route: parsedConfig.route,
        fml: new FederatedModule<S>(parsedConfig.fml),
      }

      return config
    } catch (e) {
      return undefined
    }
  }
}

export const injectRuntimeConfig = (config: RuntimeConfig<SharedData>) => {
  if (config.icon) {
    navigation.push({
      kind: 'leaf',
      // @ts-expect-error Runtime injection
      route: config.route,
      icon: config.icon,
      translationKey: 'single-view',
      dataTest: 'single-view',
    })
  }

  // @ts-expect-error Runtime injection
  microfrontends['runtime'] = config.fml

  // @ts-expect-error Runtime injection
  routes[config.route] = {
    name: config.fml.moduleFederationPluginName,
    mf: 'runtime',
    context: {
      analytics: {
        module: 'runtime',
        submodule: 'runtime',
      },
      logger: {
        codeOwners: 'runtime',
        module: 'runtime',
      },
    },
  }
}
