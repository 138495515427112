import { watch } from 'vue'
import { RouteLocationNormalized, RouteMeta, Router } from 'vue-router'

import {
  AppNavigationGuard,
  AppRouteMeta,
  createMfWrapper,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'
import {
  ISennTmsSharedData,
  opsProfileMock,
} from '@sennder/senn-node-microfrontend-interfaces'

import router, { INITIAL_ROUTE_PATH } from '@/router'
import { getStateCallbacks, getStateData, getStateProviders } from '@/store'
import { logger } from '@/services/logger/loggers'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { isLocalEnv } from '@/common/config'
import { logout } from '@/store/logoutActions'
import { wrapAuthWithLogging } from '@/modules/utils'
import { loggerInstance } from '@/services/logger'
import { microfrontends } from '@/config/microfrontends'
import { AppRoute, routes } from '@/config/routes'
import { loadUserData } from '@/store/loadUserData'
import { auth } from '@/modules/auth'
import { i18n } from '@/services/i18n'

export const getMicrofrontendData = (isAuthModule = false) => {
  const data = getStateData()
  let profile = data.profile

  if (!profile || !data.user) {
    if (isAuthModule) {
      profile = opsProfileMock
    } else {
      throw new Error('state.data.profile is not initialized')
    }
  }

  const microfrontendData: ISennTmsSharedData = {
    user: data.user,
    featureFlags: data.featureFlags,
    language: data.language,
    tenant: data.tenant,
    env: data.env,
    profile,
    type: data.type,
    org: data.org,
  }

  return microfrontendData
}

export const getMfContext = async (
  npmName: string,
  logger: MicroFrontendLogger,
  analyticsProvider: AppAnalyticsProvider
) => {
  const { getAuthHeader, getAuthToken, getToken } = getStateCallbacks()

  return {
    data: getMicrofrontendData(npmName === 'internal-auth-mf-component-v2'),
    callbacks: {
      ...getStateCallbacks(),
      getAuthHeader: wrapAuthWithLogging(getAuthHeader, logger),
      getAuthToken: wrapAuthWithLogging(getAuthToken, logger),
      getToken: wrapAuthWithLogging(getToken, logger),
      onUnauthorized: logout,
    },
    providers: {
      ...getStateProviders(),
      segment: analyticsProvider,
    },
  }
}

const featureFlagWatcher = async () =>
  watch(
    () => getStateData().featureFlags,
    async () => {
      const currentRoute = router.currentRoute.value
      if (!currentRoute) {
        return
      }
      // re-execute navigation guards for current route
      const result = await routeNavigationGuard(currentRoute, currentRoute)
      if (result !== true) {
        if (result === false) {
          router.push(INITIAL_ROUTE_PATH)
        } else {
          router.push(result)
        }
      }
    }
  )

/**
 * Micro-frontends with routes
 */
type AppRouteMicrofrontendId = (typeof routes)[AppRoute]['mf']

const addAppRoute = (
  routePath: AppRoute,
  routeMeta: AppRouteMeta<AppRouteMicrofrontendId>
) => {
  const module = microfrontends[routeMeta.mf]

  const component = async () => {
    const analyticsProvider = new AppAnalyticsProvider(
      routeMeta.context.analytics
    )
    const mfLogger = new MicroFrontendLogger(
      routeMeta.context.logger,
      () => loggerInstance
    )

    return createMfWrapper<ISennTmsSharedData>({
      allowEnvironmentOverrides: isLocalEnv(),
      getData: () => getMfContext(module.npmName, mfLogger, analyticsProvider),
      hooks: {
        failure: logger.error,
      },
      mf: {
        id: routeMeta.name,
        fml: module,
        context: {
          analytics: routeMeta.context.analytics,
          logger: routeMeta.context.logger,
        },
      },
      providers: {
        getAnalytics: () => analyticsProvider,
        getLogger: () => mfLogger,
      },
      router,
      watchers: [featureFlagWatcher],
    })
  }

  const guards = routeMeta.guards || []

  router.addRoute({
    path: routePath,
    name: routeMeta.name,
    component,
    meta: {
      layout: routeMeta.fullscreenLayout ? 'AppLayoutAuth' : 'AppLayoutDefault',
      public: routeMeta.public,
      guards,
      npmName: module.npmName,
      title: `navigation.item.${routeMeta.name}`,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: routeMeta.name,
        meta: {
          guards,
          npmName: module.npmName,
        },
        component,
      },
    ],
  })
}

export const registerRoutes = async () => {
  Object.entries(routes).forEach(([path, route]) =>
    addAppRoute(path as AppRoute, route)
  )

  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../NotFound.vue'),
    meta: {
      title: 'navigation.item.not-found',
    },
  })

  router.addRoute({
    path: '/',
    redirect: { path: INITIAL_ROUTE_PATH },
  })
}

export const initRouteGuards = (router: Router) => {
  router.beforeEach(async (to, from) => {
    await checkUserDataLoaded(from, to)
    return routeNavigationGuard(to, from)
  })

  router.beforeResolve((to) => {
    const { name, meta } = to
    setPageTitle(String(name), meta)
  })
}

const setPageTitle = (name: string, meta: RouteMeta): void => {
  const pageName =
    (meta.title && i18n.global.t(meta.title)) ||
    name.charAt(0).toUpperCase() + name.slice(1)
  document.title = `${pageName} |  sennTMS - sennder`
}

const LOGIN_ROUTE_NAME = routes['/login'].name

async function checkUserDataLoaded(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized
) {
  // If we are navigating from login page to another page, we need to load user data
  if (from.name === LOGIN_ROUTE_NAME && to.name !== LOGIN_ROUTE_NAME) {
    await loadUserData(true)
  }
}

const isAuthenticatedGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized
) => {
  if (await auth.value?.isAuthenticated()) {
    return true
  } else {
    return {
      name: LOGIN_ROUTE_NAME,
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

const isNotAuthenticatedGuard: AppNavigationGuard = async () => {
  if (await auth.value?.isAuthenticated()) {
    return { path: INITIAL_ROUTE_PATH }
  } else {
    return true
  }
}

export const routeNavigationGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const guards = [
    to.meta.public ? isNotAuthenticatedGuard : isAuthenticatedGuard,
    ...(to.meta.guards || []),
  ]
  if (guards.length === 0) {
    return true
  }

  for (const guard of guards) {
    const result = await guard(to, from)
    if (
      typeof result === 'object' ||
      typeof result === 'string' ||
      result === false
    ) {
      return result
    }
  }

  return true
}
