import { AppNavigationGuard } from '@sennder/shell-utilities'

import { INITIAL_ROUTE_PATH } from '@/router'
import { getStateFeatureFlags } from '@/store'
import {
  canAccessCharteringPages,
  canAccessSennlab,
  canAccessShipperContactsPages,
  canAccessSupplierDataDashboardPage,
  canAccessVendorProfilePage,
  canAccessCustomerDataPages,
  canAccessTenderingPages,
} from './user-access'
import { ENV, isProductionEnv } from '@/common/config'

/**
 * Router Navigation Guards
 *
 * NAMING CONVENTIONS
 *
 * Route guard name should be a verb that describes what the guard does.
 *
 * - check{Page}Access: Check if the user has access to the page
 * - redirect{Page}: Redirect the user to a different page based on some criteria
 * - restrict{Criteria}: Restrict access to the page based on some criteria
 */

/**
 * Redirect users to the Order Details page.
 */
export const redirectToOrderDetails: AppNavigationGuard = async (to) => {
  return `/ordering/${to.params.orderGroupId}${to.params.letter}`
}

export const redirectTrackAndTraceView: AppNavigationGuard = async () => {
  if (getStateFeatureFlags()['transit-redirect-execution-board']) {
    return `/single-view/transit/transit_all`
  }
  return true
}

export const enableTripPage: AppNavigationGuard = async () => {
  if (getStateFeatureFlags()['ENABLE_TRIP_PAGE']) {
    return true
  }
  return { path: INITIAL_ROUTE_PATH }
}

/**
 * Enable this page only in non-prod envs
 */
export const restrictInProduction: AppNavigationGuard = async () => {
  if (isProductionEnv()) {
    return { path: INITIAL_ROUTE_PATH }
  }
  return true
}

/**
 * This function determines whether the module should be visible to the user
 * based on the organization type.
 * Consider permission screen for the users that are not part of the chartering office
 */
export const restrictForCharteringOfficeUsers: AppNavigationGuard =
  async () => {
    if (canAccessCharteringPages()) {
      return true
    }
    return { path: INITIAL_ROUTE_PATH }
  }

export const checkSennlabAccess: AppNavigationGuard = () => {
  if (canAccessSennlab()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const checkShipperContactsAccess: AppNavigationGuard = () => {
  if (canAccessShipperContactsPages()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const checkCustomerDataAccess: AppNavigationGuard = () => {
  if (canAccessCustomerDataPages()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const redirectToSingleViewAP: AppNavigationGuard = () => {
  return '/single-view/ap-view/ap-view_all'
}

export const redirectToDefaultPage: AppNavigationGuard = () => {
  return { path: INITIAL_ROUTE_PATH }
}

export const checkSupplierDataDashboardAccess: AppNavigationGuard = () => {
  if (canAccessSupplierDataDashboardPage()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const checkVendorProfileAccess: AppNavigationGuard = () => {
  if (canAccessVendorProfilePage()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const checkTenderingAccess: AppNavigationGuard = () => {
  if (canAccessTenderingPages()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const restrictToDev: AppNavigationGuard = () => {
  if (ENV === 'dev') {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}
